import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOut from '../views/LayOut'
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'About',
        component: () => import('@/views/login/Login')
    },
    {
        path: '/load',
        name: 'load',
        component: () => import('@/assets/LoginAnimation')
    },
    {
        path: '/load2',
        name: 'load2',
        component: () => import('@/assets/LoginLoad')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register/Register')
    },
    {
        path: '/',
        name: 'home',
        component: LayOut,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('@/views/home/Home')
            },
            {
                path: 'changPwd',
                name: 'ChangPwd',
                component: () => import('@/views/register/ChangePwd')
            },
            {
                path: 'navigation',
                name: 'Navigation',
                component: () => import('@/views/navigation/Navigation')
            },
            {
                path: 'personMessage',
                name: 'PersonMessage',
                component: () => import('@/views/register/PersonMessage')
            },
            {
                path: 'notice',
                name: 'notice',
                component: () => import('@/views/notice/Notice')
            },
            {
                path: 'log',
                name: 'log',
                component: () => import('@/views/log/Log')
            },
            {
                path: 'logs',
                name: 'logs',
                component: () => import('@/views/logs/Logs')
            },
            {
                path: 'admin',
                name: 'admin',
                component: () => import('@/views/admin/Admin')
            },
            {
                path: 'loginLog',
                name: 'loginLog',
                component: () => import('@/views/loginLog/LoginLog')
            },
            {
                path: 'loginNum',
                name: 'loginNum',
                component: () => import('@/views/loginNum/LoginNum')
            },
            {
                path: 'controlLog',
                name: 'controlLog',
                component: () => import('@/views/log/controlLog')
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('@/views/user/User')
            },
            {
                path: 'hotCreate',
                name: 'hotCreate',
                component: () => import('@/views/hotCreate/HotCreate')
            },
            {
                path: 'sign',
                name: 'sign',
                component: () => import('@/views/sign/Sign')
            },
            {
                path: 'study',
                name: 'study',
                component: () => import('@/views/study/Study')
            },
            {
                path: 'website',
                name: 'website',
                component: () => import('@/views/study/Website')
            },
            {
                path: 'activeCode',
                name: 'activeCode',
                component: () => import('@/views/activeCode/ActiveCode')
            },
            {
                path: 'number',
                name: 'number',
                component: () => import('@/views/number/Number')
            },
            {
                path: 'lwHelp',
                name: 'lwHelp',
                component: () => import('@/views/lwHelp/LwHelp')
            },
            {
                path: 'orders',
                name: 'orders',
                component: () => import('@/views/orders/Orders')
            },
            {
                path: 'workHelp',
                name: 'workHelp',
                component: () => import('@/views/workHelp/WorkHelp')
            },
            {
                path: 'smCreate',
                name: 'smCreate',
                component: () => import('@/views/smCreate/SmCreate')
            },
            {
                path: '*',
                name: '404',
                component: () => import('@/views/404')
            },
            ]
    },
]

const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    // base:'/yy/',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const admin = Cookies.get('admin')
    if (!admin && to.path !== '/login' && to.path !== '/register') {
        return next('/login')
    }
    next()
})
export default router
