<template>
  <div id="mapCodeID" style="background-color: white">
    <el-container>
      <el-aside :width="asideWidth" style="position: fixed;top: 0;z-index: 1">
        <div style="height: 60px; line-height: 60px; font-size: 20px; display: flex; align-items: center; justify-content: center;">
          <img src="gptIcon.jpg" style="width: 30px;" alt="">
          <span class="logo-title" v-show="!isCollapse" style="cursor: pointer" @click="$router.push('/home')">后台管理系统</span>
        </div>
        <el-menu router :collapse="isCollapse" :collapse-transition="false" background-color="#30355B"
                 active-text-color="#fff" text-color="rgba(255, 255, 255, 0.65)" :default-active="$route.path"
                 style="border: none">
          <el-menu-item index="home">
            <i class="el-icon-s-goods"></i>
            <span slot="title">首页</span>
          </el-menu-item>

          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-monitor"></i>
              <span slot="监控管理">监控管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="item.url" style="padding-left: 55px" v-for="item in logList" :key="item.id">{{ item.name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span slot="用户管理">用户管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="item.url" style="padding-left: 55px" v-for="item in userList" :key="item.id">{{ item.name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item :index="item.url" v-for="item in options" :key="item.id" v-if="role==='ADMIN'">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container :style="styleMargin">
        <el-header style="height: 60px; line-height: 60px; display: flex; align-items: center; box-shadow: 2px 0 6px rgba(0, 21, 41, .35);">
          <i :class="collapseIcon" @click="handleCollapse" style="font-size: 26px"></i>
          <el-breadcrumb separator="/" style="margin-left: 20px">
            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/home'">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/home'">首页管理</el-breadcrumb-item>

            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/'+item.url" v-for="item in options">{{item.name}}</el-breadcrumb-item>
            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/'+item.url" v-for="item in options">{{item.name}}管理</el-breadcrumb-item>

            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/personMessage'">修改头像</el-breadcrumb-item>
            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/personMessage'">头像管理</el-breadcrumb-item>

            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/changPwd'">密码修改</el-breadcrumb-item>
            <el-breadcrumb-item :to="$route.path" v-if="$route.path==='/changPwd'">修改管理</el-breadcrumb-item>
          </el-breadcrumb>


          <div style="flex: 1; display: flex; justify-content: flex-end; align-items: center">
            <i class="el-icon-quanping" @click="handleFullScreen"  style="font-size: 25px"></i>
            <el-dropdown placement="bottom">
              <div style="display: flex; align-items: center;">
                <i class="el-icon-full-screen" style="font-size: 22px;margin-right: 10px;cursor: pointer" @click="isBlow"></i>
                <img :src="admin.avatar" alt="" style="width: 40px; height: 40px; margin: 0 5px;border-radius: 50%">
                <span style="cursor: pointer">{{admin.username}}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><span @click="$router.push('/personMessage')">修改头像</span></el-dropdown-item>
                <el-dropdown-item><span @click="$router.push('/changPwd')">修改密码</span></el-dropdown-item>
                <el-dropdown-item><span @click="quits">退出登录</span></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
            <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import request from "@/utils/request";
export default {
  name: 'AdminLayOut',
  data() {
    return {
      role:JSON.parse(Cookies.get('admin')).role,
      options:[],
      admin:JSON.parse(Cookies.get('admin')),
      isCollapse: false,
      asideWidth: '150px',
      styleMargin:'margin-left:150px',
      collapseIcon: 'el-icon-s-fold',
      logList:[],
      userList:[],
      FullScreen: false // 是否全屏
    }
  },
  created() {
    this.load()
  },
  mounted() {

  },
  methods: {
    isBlow() {
      let element = document.getElementById("mapCodeID"); // 需要全屏容器的id

      if (this.FullScreen) {
        exitFullscreen();
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.FullScreen = !this.FullScreen;

      function exitFullscreen() {
        if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape" || event.key === "Esc") {
          exitFullscreen();
          this.FullScreen = false;
        }
      });
    },
    handleFullScreen() {
      document.documentElement.requestFullscreen()
    },
    handleCollapse() {
      this.isCollapse = !this.isCollapse
      this.asideWidth = this.isCollapse ? '64px' : '150px'
      this.styleMargin=this.isCollapse ? 'margin-left:64px' : 'margin-left:150px'
      this.collapseIcon = this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
    },
    quits(){
      const id=JSON.parse(Cookies.get('admin')).id
      if(id){
        request.delete("/logs/deleteById/" + id).then(res => {
          if (res.code === '200') {
            Cookies.remove('admin');
            this.$router.push('/login')
          } else {
            this.$message.error(res.msg)
          }
        })
      }else{
        this.$router.push('/login')
      }
    },
    load(){
      request.get('/navigation/list',{
        params:{
          pid:1
        }
      }).then(res=>{
        if(res.code==='200'){
          this.options=res.data
        }
      })

      request.get('/navigation/list',{
        params:{
          pid:2
        }
      }).then(res=>{
        if(res.code==='200'){
          this.userList=res.data
        }
      })

      request.get('/navigation/list',{
        params:{
          pid:107
        }
      }).then(res=>{
        if(res.code==='200'){
          this.logList=res.data
        }
      })
    }
  }
}
</script>

<style>
.el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 25px !important;
  transition: color 0s;
}
.el-menu--collapse .el-submenu__title {
  padding: 0 20px !important;
}
.el-submenu__title>i:nth-child(1) {
  margin-top: 2px;
}
.el-submenu__title>i.el-submenu__icon-arrow {
  margin-top: -5px;
}
.el-menu-item {
  min-width: 0 !important;
  width: calc(100% - 10px);
  margin: 5px;
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 5px;
}
.el-menu--inline>.el-menu-item {
  padding-left: 50px !important;
}
.el-menu-item.is-active {
  background-color: dodgerblue !important;
}

.el-menu-item:hover {
  color: #fff !important;
}

.el-submenu__title:hover *, .el-submenu__title:hover {
  color: #fff !important;
}

.el-aside {
  box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
  /*background-color: #001529;*/
  background-color: #30355B;
  color: #fff;
  min-height: 100vh;
  transition: width .3s;
}
.el-menu--collapse .el-tooltip {
  padding: 0 15px !important;
}
.logo-title {
  margin-left: 5px;
  transition: all .3s;
  font-size: 17px;
}
.el-menu {
  transition: all .3s;
}
.el-icon-s-fold,.el-icon-s-unfold{
  cursor: pointer
}
</style>
